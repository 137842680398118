<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>组织架构</el-breadcrumb-item>
      <el-breadcrumb-item>架构管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div class="hinttitle">
        <div>
          <el-button type="primary" @click="createOrg">新建组织</el-button>
          <el-button type="success" @click="roleOrg" :disabled="disabled">新建岗位</el-button>
        </div>
        <div class="hinttitle-right">
          <el-button type="primary" class="verbal brns"></el-button>
          <div class="verbal">组织</div>
          <el-button type="success" class="verbal brns"></el-button>
          <div class="verbal">岗位</div>
        </div>
      </div>
      <div class="orabox">
        <div class="orabox-left">
          <zm-tree-org
            :data="datas"
            collapsable
            @on-node-click="nodeClick"
            @on-contextmenu="onCont"
            :define-menus="define"
            :node-draggable="draggable"
            :props="ZMprops"
            :node-delete="deletes"
            :default-expand-level="2"
          ></zm-tree-org>
        </div>
      </div>
    </el-card>

    <!-- 新建组织 -->
    <el-dialog :title="Orgtitle" :visible.sync="createVisible" width="30%" :before-close="CreateClose">
      <el-form :model="Dform" ref="Dform" :rules="rules">
        <el-form-item label="组织名称" label-width="150px" prop="name">
          <div class="el-select"><el-input v-model="Dform.name" placeholder="请输入组织名称"></el-input></div>
        </el-form-item>
        <el-form-item label="组织描述" label-width="150px">
          <div class="el-select"><el-input v-model="Dform.desc" placeholder="请输入组织描述"></el-input></div>
        </el-form-item>
        <el-form-item label="是否容器组织" label-width="150px">
          <el-switch v-model="Dform.container_status" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0" @change="switchw"></el-switch>
        </el-form-item>
        <el-form-item label="上级归属" label-width="150px" v-if="tantype === 'add'">
          <el-tree
            :default-expanded-keys="defaultShowNodes"
            :data="options"
            ref="tree"
            :props="defaultProps"
            node-key="id"
            show-checkbox
            check-strictly
            @check-change="handleNodeClick"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createNo">取 消</el-button>
        <el-button type="primary" @keyup.enter.native="createOk()" @click="createOk(tantype)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新建岗位 -->
    <el-dialog :title="gangtitle" :visible.sync="roleVisible" width="30%" :before-close="RoleClose">
      <el-form :model="Gform" ref="Gform" :rules="rules">
        <el-form-item label="岗位名称" label-width="150px" prop="name">
          <div class="el-select"><el-input v-model="Gform.name" placeholder="请输入岗位名称"></el-input></div>
        </el-form-item>
        <el-form-item label="岗位描述" label-width="150px">
          <div class="el-select"><el-input v-model="Gform.desc" placeholder="请输入岗位描述"></el-input></div>
        </el-form-item>
        <el-form-item label="是否是管理岗" label-width="100px" class="NoOk">
          <div class="el-select">
            <el-radio-group v-model="Gform.manage_role">
              <el-radio label="0">否</el-radio>
              <el-radio label="1">是</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="上级归属" label-width="150px" v-if="gangtype === 'add'">
          <el-tree
            :default-expanded-keys="defaultShowNodes"
            :data="options"
            ref="trees"
            :props="defaultProps"
            node-key="id"
            show-checkbox
            check-strictly
            @check-change="RolehandleNodeClick"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleNo">取 消</el-button>
        <el-button type="primary" @keyup.enter.native="roleOk()" @click="roleOk(gangtype)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Organtree, Organcreate, Organrole, Organtreenode, Orgroledelete, Orgnodedelete, Orgedit, Orgeditgang } from '../../../api/personnel.js'
export default {
  data() {
    return {
      defaultShowNodes: [],
      Orgtitle: '',
      gangtitle: '',
      tantype: 'add',
      gangtype: 'add',
      createVisible: false, // 新建组织弹窗
      roleVisible: false, // 新建岗位
      OrgdefaultList: [],
      defaultList: [],
      options: [],
      disabled: true,
      defaultProps: {
        children: 'children',
        label: 'name',
        disabled: this.disabledFn
      },
      Dform: {
        name: '',
        desc: '',
        parent: '',
        container_status: 1,
        node_id: ''
      },
      Gform: {
        id: '',
        name: '',
        desc: '',
        node_id: '',
        manage_role: '0'
      },
      datas: {},
      define: [
        { name: '复制文本', command: 'copy' },
        { name: '新增组织', command: 'adds' },
        { name: '新增岗位', command: 'role' },
        { name: '编辑节点', command: 'edit' },
        { name: '删除节点', command: 'delete' }
      ],
      draggable: false,
      rules: {
        name: [
          { required: true, message: '请输入组织标题', trigger: 'blur' },
          { min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
        ]
      },
      ZMprops: { id: 'id', label: 'name' }
    }
  },
  mounted() {
    this.Organtrees()
    this.nodetrees()
  },
  methods: {
    // 节点点击事件
    nodeClick(e, data) {
      // console.log(data)
    },

    // 递归默认展开
    getDefaultShowNodes(num, children) {
      --num
      if (num >= 0) {
        for (var i = 0; i < children.length; i++) {
          this.defaultShowNodes.push(children[i].id)
          if (children[i].children) {
            this.getDefaultShowNodes(num, children[i].children)
          }
        }
      }
    },

    // 右键
    onCont(command) {
      console.log(command)
      if (command.command === 'edit') {
        if (command.node.type === 'node') {
          this.tantype = 'edit'
          let arr = command.node.link
          let userNames = arr
            .map(item => {
              return item.name
            })
            .join('>')
          this.Orgtitle = userNames + '>' + '组织编辑'
          this.createVisible = true
          this.Dform.name = command.node.name
          this.Dform.desc = command.node.desc
          this.Dform.parent = command.node.parent
          this.Dform.node_id = command.node.node_id
          this.Dform.container_status = Number(command.node.container_status)
        } else if (command.node.type === 'role') {
          this.gangtype = 'edit'
          let arr = command.node.link
          let userNames = arr
            .map(item => {
              return item.name
            })
            .join('>')
          this.gangtitle = userNames + '>' + '岗位编辑'
          this.roleVisible = true
          this.Gform.name = command.node.name
          this.Gform.desc = command.node.desc
          this.Gform.manage_role = String(command.node.manage_role)
          this.Gform.id = command.node.id
        }
      } else if (command.command === 'adds') {
        if (command.node.type === 'node') {
          console.log('新增组织')
          this.createVisible = true
          this.tantype = 'Set'
          this.Dform.parent = command.node.id
          let arr = command.node.link
          let userNames = arr
            .map(item => {
              return item.name
            })
            .join('>')
          this.Orgtitle = userNames + '>' + '新建组织'
          console.log(this.Dform)
        } else if (command.node.type === 'role') {
          console.log('新建岗位')
        }
      } else if (command.command === 'role') {
        if (command.node.type === 'node') {
          console.log('新增岗位')
          this.roleVisible = true
          this.gangtype = 'Set'
          this.Gform.node_id = command.node.id
          let arr = command.node.link
          let userNames = arr
            .map(item => {
              return item.name
            })
            .join('>')
          this.gangtitle = userNames + '>' + '新建岗位'
        }
      }
    },

    disabledFn() {
      if (this.tantype === 'edit') {
        return true
      }
    },

    // 删除节点
    deletes(e) {
      // console.log(e)
      if (e.type === 'node') {
        this.$confirm('此操作将删除该组织, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.Orgnodedeletes(e.id)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      } else if (e.type === 'role') {
        this.$confirm('此操作将删除该岗位, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.Orgroledeletes(e.id)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
    },

    switchw() {
      // console.log(this.Dform.container_status)
    },

    // 添加选中上级
    handleNodeClick(data, checked) {
      // console.log(data, checked)
      if (checked) {
        this.$refs.tree.setCheckedNodes([data])
        this.Dform.parent = data.id
      } else {
        // this.form.tag = ''
      }
    },

    RolehandleNodeClick(data, checked) {
      // console.log(data, checked)
      if (checked) {
        this.$refs.trees.setCheckedNodes([data])
        this.Gform.node_id = data.id
      } else {
        // this.form.tag = ''
      }
    },

    // 新建组织按钮
    createOrg() {
      this.Orgtitle = '新建组织'
      this.createVisible = true
    },

    // 新建岗位按钮
    roleOrg() {
      this.gangtitle = '新建岗位'
      this.roleVisible = true
    },

    // 新建组织弹窗关闭
    CreateClose() {
      this.createNo()
    },

    // 新建岗位弹窗关闭
    RoleClose() {
      this.roleNo()
    },

    // 新建组织弹窗确认
    createOk(type) {
      // console.log(type)

      switch (type) {
        case 'add':
          this.$refs['Dform'].validate(v => {
            if (v) {
              this.Organcreates()
            }
          })
          break
        case 'edit':
          this.$refs['Dform'].validate(v => {
            if (v) {
              this.Orgedits()
            }
          })
          // console.log(type)
          break
        case 'Set':
          this.$refs['Dform'].validate(v => {
            if (v) {
              this.Organcreates()
            }
          })
          break
      }
    },

    // 新建岗位确认
    roleOk(type) {
      // console.log(type)

      switch (type) {
        case 'add':
          this.$refs['Gform'].validate(v => {
            // console.log(v)
            if (v) {
              if (this.Gform.node_id !== '') {
                // console.log(this.Gform)
                this.Organroles()
              } else {
                this.$message({
                  message: '请选择岗位归属的组织',
                  type: 'warning'
                })
              }
            }
          })
          break
        case 'edit':
          this.$refs['Gform'].validate(v => {
            if (v) {
              // console.log(this.Gform)
              this.Orgeditgangs(this.Gform)
            }
          })
          // console.log(type)
          break
        case 'Set':
          this.$refs['Gform'].validate(v => {
            // console.log(v)
            if (v) {
              if (this.Gform.node_id !== '') {
                // console.log(this.Gform)
                this.Organroles()
              } else {
                this.$message({
                  message: '请选择岗位归属的组织',
                  type: 'warning'
                })
              }
            }
          })
          break
      }
    },

    // 新建岗位取消
    roleNo() {
      this.Gform.id = ''
      this.Gform.name = ''
      this.Gform.desc = ''
      this.Gform.node_id = ''
      this.Gform.manage_role = '0'
      this.roleVisible = false
      if (this.gangtype === 'add') {
        this.$refs.trees.setCheckedKeys([])
      }
      this.gangtype = 'add'
    },

    // 新建组织取消
    createNo() {
      this.createVisible = false
      this.Dform.name = ''
      this.Dform.desc = ''
      this.Dform.parent = ''
      this.OrgdefaultList = []
      this.Dform.node_id = ''
      this.Orgtitle = ''
      this.tantype = 'add'
      this.Dform.container_status = 1
      // this.$refs.tree.setCheckedKeys([])
    },

    async Orgeditgangs(Gform) {
      const { data } = await Orgeditgang({ ...Gform })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '修改岗位成功',
          type: 'success'
        })
        this.roleNo()
        this.Organtrees()
      } else {
        this.$message({
          message: `${data.message}`,
          type: 'error'
        })
        this.roleNo()
      }
    },

    // 获取组织树
    async Organtrees() {
      const { data } = await Organtree()
      // console.log(data.data)
      if (data.data.length === 0) {
        // console.log('数组是空的')
        this.datas = {}
        this.disabled = true
      } else {
        this.datas = data.data
        let Objs = data.data
        this.disabled = false
        this.getChild(Objs, '数据')
      }
    },

    // 新建组织
    async Organcreates() {
      const { data } = await Organcreate({ ...this.Dform })
      // console.log(data)
      if (data.code == 200) {
        this.createVisible = false
        this.$message({
          message: '添加组织成功',
          type: 'success'
        })
        this.Dform.name = ''
        this.Dform.desc = ''
        this.Dform.parent = ''
        // this.$refs.tree.setCheckedKeys([])
        this.Organtrees()
        this.nodetrees()
        this.createNo()
      } else {
        this.$confirm(`${data.message}`, {
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(() => {
            this.Dform.name = ''
            this.Dform.desc = ''
            this.Dform.parent = ''
            this.createVisible = false
            this.$refs.tree.setCheckedKeys([])
          })
          .catch(() => {
            this.Dform.name = ''
            this.Dform.desc = ''
            this.Dform.parent = ''
            this.createVisible = false
            this.$refs.tree.setCheckedKeys([])
          })
      }
    },

    // 编辑组织
    async Orgedits() {
      const { data } = await Orgedit({ ...this.Dform })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '修改组织成功',
          type: 'success'
        })
        this.createNo()
        this.Organtrees()
        this.nodetrees()
      } else {
        this.$confirm(`${data.message}`, {
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(() => {
            this.createNo()
            this.Organtrees()
            this.nodetrees()
          })
          .catch(() => {
            this.createNo()
            this.Organtrees()
            this.nodetrees()
          })
      }
    },

    // 新建岗位
    async Organroles() {
      const { data } = await Organrole({ ...this.Gform })
      // console.log(data)
      if (data.code == 200) {
        this.roleVisible = false
        this.$message({
          message: '添加岗位成功',
          type: 'success'
        })
        this.Gform.name = ''
        this.Gform.desc = ''
        this.Gform.node_id = ''
        this.Gform.manage_role = '0'
        // this.$refs.trees.setCheckedKeys([])
        this.Organtrees()
        this.nodetrees()
        this.roleNo()
      } else {
        this.$confirm(`${data.message}`, {
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(() => {
            this.Gform.name = ''
            this.Gform.desc = ''
            this.Gform.node_id = ''
            this.Gform.manage_role = '0'
            this.roleVisible = false
            this.$refs.trees.setCheckedKeys([])
          })
          .catch(() => {
            this.Gform.name = ''
            this.Gform.desc = ''
            this.Gform.node_id = ''
            this.Gform.manage_role = '0'
            this.roleVisible = false
            this.$refs.trees.setCheckedKeys([])
          })
      }
    },

    // 获取组织树
    async nodetrees() {
      const { data } = await Organtreenode()
      this.options = []
      this.options.push(data.data)
      this.getDefaultShowNodes(2, this.options)
    },

    // 删除岗位
    async Orgroledeletes(id) {
      const { data } = await Orgroledelete({ role_id: id })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '删除岗位成功',
          type: 'success'
        })
        this.Organtrees()
        this.nodetrees()
      } else {
        this.$message({
          message: `${data.message}`,
          type: 'error'
        })
      }
    },

    // 删除组织
    async Orgnodedeletes(id) {
      const { data } = await Orgnodedelete({ node_id: id })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '删除组织成功',
          type: 'success'
        })
        this.Organtrees()
        this.nodetrees()
      } else {
        this.$message({
          message: `${data.message}`,
          type: 'error'
        })
      }
    },

    // 递归
    getChild(valueArr, type) {
      // console.log(valueArr, type)
      let level = 0
      let maxLevel = 0
      const loop = (data, level) => {
        // console.log(data, 'data')
        data.children.forEach(item => {
          item.level = level
          if (level > maxLevel) {
            maxLevel = level
          }
          // console.log(item)
          if ('children' in item) {
            if (item.type === 'node') {
              // item.style = { backgroundColor: '#409EFF', color: 'white', borderRadius: ' 9px', maxHeight: '50px' }
              item.style = { backgroundColor: '#409EFF', color: 'white', borderRadius: ' 9px' }
            } else {
              // item.style = { backgroundColor: '#85CE61', color: 'white', borderRadius: ' 9px', maxHeight: '50px' }
              item.style = { backgroundColor: '#85CE61', color: 'white', borderRadius: ' 9px'}
              // item.disabled = true
            }
            if (item.children.length > 0) {
              loop(item, level + 1)
            }
            if (item.children.length == 0) {
              item.children = null
            }
          }
        })
      }
      loop(valueArr, 1)
    }
  }
}
</script>

<style  lang="less">
.hinttitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .hinttitle-right {
    width: 15%;
    display: flex;
    align-items: center;
    color: #a5a0a0;
    .verbal {
      margin-left: 2%;
    }
    .brns {
      margin-left: 5%;
    }
  }
}
.orabox {
  box-sizing: border-box;
  width: 100%;
  height: 80vh;
  // border: 1px solid #000;
  display: flex;
  .orabox-left {
    width: 100%;
    height: 100%;
    // border: 1px solid #000;
    background-color: #f2f2f2;
  }
  .orabox-right {
    width: 40%;
    height: 100%;
    border: 1px solid red !important;
  }
}
.tree-org-node__text {
  padding: 0px 15px !important;
}
.tree-org-node__content {
  background-color: #409eff;
  color: white;
  border-radius: 9px !important;
}
.NoOk {
  margin-left: 10%;
}
</style>